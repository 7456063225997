<template>
  <div class="content">
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <h1 class="title is-1">Recipes <span class="tag is-large is-primary">Total: {{ recipes.length }} (Free: {{ this.freeRatio }}%)</span></h1>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <b-button outlined type="is-primary" class="right" id="add-button" icon-left="plus" @click="createNewRecipe">Add</b-button>
        </div>
      </div>
    </div>
    <b-input rounded class="mb-4" icon="magnify"
      placeholder="Carrots, miso, freaky"
      v-model="query"
      @input="performSearch">
    </b-input>
    <b-table
      :data="recipes"
      :hoverable="true"
      :striped="true"
      :mobile-cards="false"

      @click="openRecipe" >
      <b-table-column width=48 label="Image" sortable v-slot="props">
        <figure class="m-0 p-0 image is-48x48">
          <img v-if="rowImageSrc(props.row) == null" class="thumbnail"  src="@/assets/listing-placeholder.png">
          <img v-else class="thumbnail" :src="rowImageSrc(props.row)">
        </figure>
      </b-table-column>
      <b-table-column field="title" label="Title" sortable v-slot="props">
        <p class="has-text-weight-bold">
          {{ rowTitle(props.row) }}
        </p>
      </b-table-column>
      <b-table-column v-if="isDesktop()" field="status" width=60 label="Status" sortable centered v-slot="props">
          <span
            :class="[
                    'tag',
                    'is-medium',
                    {'is-danger is-light': props.row.status == 'draft'},
                    {'is-warning': props.row.status == 'test'},
                    {'is-info': props.row.status == 'fix'},
                    {'is-success': props.row.status == 'live'}
                  ]">
              <b>{{ props.row.status }}</b>
          </span>
      </b-table-column>
      <b-table-column v-if="isDesktop()" field="is_free" width=50 label="Free" sortable centered v-slot="props">
          <b-tag
            size="is-medium"
            class="mr-1"
            :type="props.row.is_free ? 'is-success' : 'is-default'">
          <b-icon 
            size="is-small"
            :type="props.row.is_free ? 'is-white' : 'is-default'"
            icon = "star">
          </b-icon>
        </b-tag>
      </b-table-column>
      <b-table-column field="servings" label="Serves" sortable v-slot="props">
        <p class="has-text-weight-bold has-text-centered">
          {{ props.row.servings }}
        </p>
      </b-table-column>
      <!-- <b-table-column v-if="isDesktop()" field="notes" label="Has notes" sortable v-slot="props">
        <b>{{ props.row.notes != null ? "Notes" : ""}}</b>
      </b-table-column> -->
      <b-table-column field="variant" label="Variant" sortable v-slot="props">
        <b>{{ props.row.is_variant ? "Variant" : ""}}</b>
      </b-table-column>
      <b-table-column v-if="isDesktop()" field="created_at" width=60 label="Created" sortable centered v-slot="props">
          <span class="tag is-primary">
              {{ new Date(props.row.created_at).toLocaleDateString() }}
          </span>
      </b-table-column>
      <b-table-column v-if="isDesktop()" field="id" width=60 label="ID" v-slot="props">
          <p class="is-size-7">{{ props.row.id }}</p>
      </b-table-column>
      <b-table-column field="options" custom-key="actions" width=20 v-slot="props">
        <b-dropdown aria-role="list" position="is-bottom-left" @click.native.stop>
            <template #trigger>
              <b-button icon-right="dots-vertical" />
            </template>
            <b-dropdown-item :disabled="props.row.is_variant" aria-role="listitem" @click="duplicateRecipe(props.row)">
              <b-icon size="is-small" icon="content-copy"></b-icon>
              {{ props.row.is_variant ? "Duplicating unavailable":"Duplicate" }}
            </b-dropdown-item>
            <b-dropdown-item class="has-text-danger" aria-role="listitem" @click="deleteRecipe(props.row)">
              <b-icon  size="is-small" icon="delete"></b-icon>
              Delete
            </b-dropdown-item>
        </b-dropdown>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import db from "@/firebase/db"
import debounce from 'lodash/debounce'
import search from '../search'

export default {
  name: 'RecipeListView',
  data() {
    return {
      recipes: [],
      query: ""
    }
  },
  firestore: {
    recipes: db.collection('recipes').orderBy("created_at", "desc")
  },
  computed: {
    freeRatio() {
      let total = this.recipes.length;
      let free = this.recipes.filter(recipe => recipe.is_free).length;
      return Math.round(free / total * 100);
    }
  },
  methods: {
    performSearch: debounce(function (query) {
        // console.log(query);
        // String update
        if (this.query !== query) {
            this.query = query;
            this.recipes = [];
        }
        // String cleared
        if (!query.length) {
            
            db.collection("recipes").orderBy("created_at", "desc").get().then(snapshot => {
              this.recipes = snapshot.docs.map(docSnapshot => {
                let doc = docSnapshot.data();
                doc.id = docSnapshot.id;
                return doc;
              });
            });
            return;
        }
        
        let searchParameters = {
          'q'         : query,
          'preset'    : 'admin_recipe_search',
          'sort_by': '_text_match:desc',
          'per_page': '100'
        };

        // NB!!! 
        // This search is hardcoded to the live Typsense Cluster! 
        // TODO: install a local version for developing locally! 
        let that = this;
        search.collections('recipes')
          .documents()
          .search(searchParameters)
          .then(function (searchResults) {
            that.recipes = searchResults.hits.map((hit) => hit.document );
          })
          .finally(() => {
            that.isLoading = false;
          })
      }, 500),
    isDesktop() {
      if (screen.width <= 760) {
        return false;
      } else {
        return true;
      }
    },
    createNewRecipe() {
      db.collection("recipes").add({
          created_at: Date.now(),
          title: "New Recipe",
          steps: [],
          ingredients: [],
          tips: [],
          servings: 2,
          image: {},
          images: [],
          status: "draft",
          is_variant: false,
          is_free: true
      })
      .then((docRef) => {
          console.log("Document written with ID: ", docRef.id);
          this.$router.push({ name: 'RecipeView', params: { id: docRef.id } })
      })
      .catch((error) => {
          console.error("Error adding document: ", error);
      });
    },
    openRecipe(recipe) {
      this.$router.push({ name: 'RecipeView', params: { id: recipe.id } })
    },
    deleteRecipe(recipe) {
        this.$buefy.dialog.confirm({
            title: 'Deleting recipe',
            message: `Are you sure you want to delete recipe: <b><i>"${recipe.title}"</i></b>? <br /><br />This action cannot be undone.`,
            confirmText: 'Delete Recipe',
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => {
              db.collection("recipes").doc(recipe.id)
              .delete()
              .then(() => {
                this.$buefy.toast.open({type: 'is-success', message: `Recipe successfully deleted!`})
                this.performSearch(this.query); // reload the list
              }).catch((error) => {
                this.$buefy.toast.open({type: 'is-danger', message: `Error deleting recipe: ${error}`})
              });
            }
        })
    },
    duplicateRecipe(recipe) {
      if (recipe.is_variant){
        this.$buefy.toast.open({type: 'is-danger', message: `You can't duplicate a variant!`});
        return;
      }
      db.collection("recipes").doc(recipe.id)
        .get()
        .then((doc) => {
          if (doc.exists && doc.data()) {
            let newData = doc.data();
            newData['created_at'] = Date.now();
            newData['status'] = "draft";
            newData['is_variant'] = true;
            newData['variants'] = null; // clear any variants from the base
            newData['name'] = `Copy`; // give ourselves a default internal name

            // set our base recipe info
            let baseId = recipe.is_variant ? recipe.base_recipe.id : recipe.id;
            let baseTitle = recipe.is_variant ? recipe.base_recipe.title : recipe.title;
            newData['base_recipe'] = {
              'id': baseId,
              'title': baseTitle
            };
            
            return db.collection("recipes").add(newData)
              .then((docRef) => {
                // add to the list of variants for a recipe
                this.addVariantToBase(docRef.id, recipe);
                this.$buefy.toast.open({type: 'is-success', message: `Recipe successfully duplicated! ${docRef.id}`});
                this.performSearch(this.query); // reload the list
              });
          }
        })
        .catch((error) => {
          console.log(`Error duplicating base recipe: ${error}`);
          this.$buefy.toast.open({type: 'is-danger', message: `Error duplicating recipe ${recipe.id}: ${error}`})
        });
    },
    addVariantToBase (variant, baseRecipe) {

      if (baseRecipe.recipe_variants == null){
        baseRecipe.recipe_variants = {};
      }
      baseRecipe.recipe_variants[variant] = {'id': variant};  
      
      console.log(`Merged: ${baseRecipe.recipe_variants}`);
      return db.collection('recipes')
        .doc(baseRecipe.id)
        .set(baseRecipe)
        .then(() => {
          this.$buefy.toast.open({type: 'is-success', message: `Base recipe successfully updated!`});
        })
        .catch((error) => {
          console.log(`Error saving base recipe: ${error}`);
          this.$buefy.toast.open({type: 'is-danger', message: `Error saving base recipe: ${error}`});
        });
    },
    rowImageSrc(recipe) {
      if (recipe.images == null){
        return null;
      }
      return (recipe.images.length != 0) ? recipe.images[0].src : null;
    },
    rowTitle(recipe) {
      if (recipe.name != null && recipe.name != ""){
        return `${recipe.title} (${recipe.name})`
      }
      return recipe.title
    }
  }
}

</script>
